import _ from 'lodash'
import * as types from '../../constants/actionTypes/ordersActionTypes'

const defaultState = {
  entriesById: {},
  entryIds: [],
  allVoucherTags: [],
  totalPayed: [],
  totalCount: 0,
  filters: {
    term: '',
    pagination: { current: 1, pageSize: 10 },
    period: [],
    sort: { by: 'createdAt', order: 'descend' },
    paymentMethods: [],
    productVariantIds: [],
    voucherId: null,
    voucherTags: [],
    discountId: null,
    shopId: null,
    countryIds: [],
    stateIds: [],
    stateNames: [],
    paid: null
  }
}

export default function ordersReducer (state = defaultState, action) {
  switch (action.type) {
    case types.ORDER_FETCH:
      return {
        ...state,
        entriesById: { ...state.entriesById, [action.data.id]: action.data }
      }
    case types.ORDER_SEND_EMAIL:
      return {
        ...state,
        entriesById: { ...state.entriesById, [action.id]: { ...state.entriesById[action.id], sendingEmail: true } }
      }
    case types.ORDER_SUBMIT_TRACKING:
      return {
        ...state,
        entriesById: { ...state.entriesById, [action.id]: { ...state.entriesById[action.id], submittingTracking: true } }
      }
    case types.ORDERS_CHANGE_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
          pagination: {
            ...state.filters.pagination,
            current: 1,
            ...action.payload.pagination
          }
        }
      }
    }
    case types.ORDERS_FETCH:
      return {
        ...state,
        entriesById: { ...state.entriesById, ..._.keyBy(action.data.records, 'id') },
        entryIds: _.map(action.data.records, 'id'),
        totalCount: action.data.totalCount,
        totalPayed: action.data.totalPayed,
        allVoucherTags: action.data.allVoucherTags
      }
    default:
      return state
  }
}
