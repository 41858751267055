const users = {
  can_show_users: ['users', 'users-show'],
  can_create_user: ['users', 'users-create'],
  can_edit_user: ['users', 'users-edit'],
  can_change_role_user: ['users', 'users-change-role']
}

const roles = {
  can_show_roles: ['roles', 'roles-show'],
  can_create_role: ['roles', 'roles-create'],
  can_edit_role: ['roles', 'roles-edit']
}

const customers = {
  can_show_customers: ['customers', 'customers-show'],
  can_create_customer: ['customers', 'customers-create'],
  can_edit_customer: ['customers', 'customers-edit']
}

const discounts = {
  can_show_discounts: ['discounts', 'discounts-show'],
  can_create_discount: ['discounts', 'discounts-create'],
  can_edit_discount: ['discounts', 'discounts-edit']
}

const vouchers = {
  can_show_vouchers: ['vouchers', 'vouchers-show'],
  can_create_voucher: ['vouchers', 'vouchers-create'],
  can_edit_voucher: ['vouchers', 'vouchers-edit']
}

const freeForms = {
  can_show_free_forms: ['free-forms', 'free-forms-show'],
  can_create_free_form: ['free-forms', 'free-forms-create'],
  can_edit_free_form: ['free-forms', 'free-forms-edit']
}

const websites = {
  can_show_websites: ['websites', 'websites-show'],
  can_create_website: ['websites', 'websites-create'],
  can_edit_website: ['websites', 'websites-edit']
}

const redirects = {
  can_show_redirects: ['redirects', 'redirects-show'],
  can_create_redirect: ['redirects', 'redirects-create'],
  can_edit_redirect: ['redirects', 'redirects-edit']
}

const pages = {
  can_show_pages: ['pages', 'pages-show'],
  can_create_page: ['pages', 'pages-create'],
  can_edit_page: ['pages', 'pages-edit']
}

const blocks = {
  can_show_blocks: ['blocks', 'blocks-show'],
  can_create_block: ['blocks', 'blocks-create'],
  can_edit_block: ['blocks', 'blocks-edit']
}

const shops = {
  can_show_shops: ['shops', 'shops-show'],
  can_create_shop: ['shops', 'shops-create'],
  can_edit_shop: ['shops', 'shops-edit']
}

const products = {
  can_show_products: ['products', 'products-show'],
  can_create_product: ['products', 'products-create'],
  can_edit_product: ['products', 'products-edit']
}

const orders = {
  can_show_orders: ['orders', 'orders-show'],
  can_create_order: ['orders', 'orders-create'],
  can_edit_order: ['orders', 'orders-edit'],
  can_send_email_order: ['orders', 'orders-send-email'],
  can_submit_tracking_order: ['orders', 'orders-submit-tracking']
}

const categories = {
  can_show_categories: ['categories', 'categories-show'],
  can_create_category: ['categories', 'categories-create'],
  can_edit_category: ['categories', 'categories-edit']
}

const specifications = {
  can_show_specifications: ['specifications', 'specifications-show'],
  can_create_specification: ['specifications', 'specifications-create'],
  can_edit_specification: ['specifications', 'specifications-edit']
}

const articles = {
  can_show_articles: ['articles', 'articles-show'],
  can_create_article: ['articles', 'articles-create'],
  can_edit_article: ['articles', 'articles-edit']
}

const tags = {
  can_show_tags: ['tags', 'tags-show'],
  can_create_tag: ['tags', 'tags-create'],
  can_edit_tag: ['tags', 'tags-edit']
}

const dashboard = {
  can_show_dashboard: ['dashboard', 'dashboard-show']
}

export const permissionsArray = {
  ...articles,
  ...tags,
  ...users,
  ...roles,
  ...categories,
  ...customers,
  ...shops,
  ...websites,
  ...redirects,
  ...pages,
  ...blocks,
  ...products,
  ...orders,
  ...discounts,
  ...vouchers,
  ...freeForms,
  ...specifications,
  ...dashboard
}
