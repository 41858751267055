import { Switch } from 'react-router-dom'

import { PrivateRoute } from '../PrivateRoute'
import { useAllow } from '../../helpers/hooks/usePermissions'
import { OrdersScreen } from '../../screens/Orders/OrdersScreen'
import { OrderFormScreen } from '../../screens/Orders/OrderFormScreen'
import { useSubscription } from '@logux/redux'
import { useSelector } from 'react-redux'

export const OrdersRoute = ({ isAuthenticated }) => {
  const allow = useAllow()
  const filters = useSelector((state) => state.orders.filters)
  const {
    pagination, term, period, sort, paymentMethods, paid, productVariantIds,
    voucherId, voucherTags, discountId, shopId, countryIds, stateIds, stateNames
  } = filters
  const { current, pageSize } = pagination
  const isLoading = useSubscription(['countries', 'states'])
  const isLoadingOrders = useSubscription([
    {
      channel: 'orders',
      current,
      pageSize,
      term,
      period,
      sort,
      paymentMethods,
      productVariantIds,
      voucherId,
      voucherTags,
      discountId,
      shopId,
      countryIds,
      stateIds,
      stateNames,
      paid
    }
  ])
  return (
    <Switch>
      <PrivateRoute
        allow={allow('can_create_order')}
        path="/orders/new"
        exact
        isAuthenticated={isAuthenticated}
        component={OrderFormScreen}
      />
      <PrivateRoute
        allow={allow('can_edit_order')}
        path="/orders/:id"
        exact
        componentProps={{ isLoading }}
        isAuthenticated={isAuthenticated}
        component={OrderFormScreen}
      />
      <PrivateRoute
        allow={allow('can_show_orders')}
        path="/orders"
        exact
        isAuthenticated={isAuthenticated}
        componentProps={{ isLoading: isLoading && isLoadingOrders }}
        component={OrdersScreen}
      />
    </Switch>
  )
}
