import { useCallback, useEffect, useState } from 'react'
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Select,
  DatePicker,
  Checkbox,
  notification
} from 'antd'
import { css } from '@linaria/core'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import { fm } from '../../lang'
import {
  createDiscount,
  updateDiscount,
  deleteDiscount,
  selectDiscount,
  resetDiscount
} from '../../data/actions/discounts'
import { useRecordHook } from '../../helpers/hooks/useRecordHook'
import { FormActionBox } from '../Shared/FormActionBox'
import { ProductVariantSelect } from '../Shared/ProductVariantSelect'
import { usePush } from '../../data/actions/routes'
import { useBreadcrumb } from '../../helpers/hooks/useBreadcrumb'
import { onKeyDownForm } from '../../helpers/form'
import { showError } from '../../data/actions/ui'
import { TranslationTabs } from '../Shared/Translations/TranslationTabs'
import { emptyTranslation } from '../../data/reducers/discountsReducer'

const { RangePicker } = DatePicker
const { Option } = Select

const formClass = css`
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding-bottom: 2px;
  }
`

const rangeConfig = {
  rules: [
    {
      type: 'array',
      required: true,
      message: 'Please select date'
    }
  ]
}
const initialFormValues = { values: {}, errors: [] }

export const DiscountForm = ({ isLoading, path }) => {
  const breadcrumb = useBreadcrumb(path)
  const push = usePush()
  const { newRecord, params } = useRecordHook()
  const { id } = params
  const blocks = useSelector((state) => state.blocks.entries).filter((el) => !el.system)
  const { entry } = useSelector((state) => state.discounts)
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState(initialFormValues)
  const [form] = Form.useForm()
  const handleBack = useCallback(() => {
    push('/discounts')
  }, [push])

  useEffect(() => {
    if (!isLoading) {
      if (entry === undefined) {
        showError(dispatch, 'record-not-found')
        resetDiscount(dispatch)
        handleBack()
      } else {
        form.setFieldsValue({
          ...entry,
          activeDate: [entry.startAt && moment(entry.startAt), entry.endAt && moment(entry.endAt)]
        })
        form.setFieldsValue(formValues.values)
        form.setFields(formValues.errors)
      }
    }
  }, [entry, dispatch, form, formValues, handleBack, isLoading])
  useEffect(() => {
    if (!isLoading && !newRecord) {
      selectDiscount(dispatch, id)
    } else if (newRecord) {
      resetDiscount(dispatch)
    }
  }, [isLoading, dispatch, id, newRecord])

  const onFinish = useCallback(async () => {
    const values = form.getFieldsValue(true)

    const productVariantIds = values.productVariantIds.flatMap((id) => {
      const ids = id.split(',')
      return ids.length > 1 ? ids.splice(1) : ids
    })
    const [startAt, endAt] = values.activeDate

    try {
      setFormValues({ values, errors: formValues.errors.map(({ name }) => ({ name, errors: [] })) })

      if (newRecord) {
        const createParams = { ...entry, ...values, productVariantIds, startAt, endAt }
        await createDiscount(dispatch, createParams)
        push(`/discounts/${createParams.id}`)
        notification.success({ message: 'Discount successfully created' })
      } else {
        const updateParams = { id, ...values, productVariantIds, startAt, endAt }
        await updateDiscount(dispatch, updateParams)
        notification.success({ message: 'Discount successfully updated' })
      }
    } catch (e) {
      const errors = e.action.reason.map(({ path, message }) => (
        {
          name: path,
          errors: [message]
        }
      ))

      setFormValues({ values, errors })
    }
  }, [dispatch, entry, form, id, newRecord, push])
  const deleteRecord = useCallback(() => {
    deleteDiscount(dispatch, id, push)
  }, [id, push, dispatch])
  const cardTitle = newRecord ? 'new' : 'edit'
  const discountList = [
    { value: 'fixed', label: 'Fixed amount' },
    { value: 'percentage', label: 'Percentage' }
  ]

  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm(`discount.form.${cardTitle}`)}
      onBack={handleBack}
    >
      <Form
        form={form}
        layout="vertical"
        className={formClass}
        onFinish={onFinish}
        onKeyDown={onKeyDownForm}
      >
        <Row gutter={[16, 16]}>
          <Col xs={16}>
            <Card
              size="small"
              loading={isLoading}
              title={fm('product.basic')}
            >
              <Form.Item
                name="name"
                label={fm('label.name')}
                rules={[
                  {
                    required: true,
                    message: 'Please input name'
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="discountType"
                label={fm('label.discount-types')}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please select type'
                  }
                ]}
              >
                <Select placeholder="Please select a discount type">
                  {discountList.map((l) => (
                    <Option key={l.value} value={l.value}>
                      {l.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="value"
                label={fm('label.value')}
                rules={[
                  {
                    required: true,
                    message: 'Please input value'
                  }
                ]}
              >
                <InputNumber
                  min={0}
                  step={1}
                  style={{ width: '100%' }}
                  precision={2}
                  stringMode
                />
              </Form.Item>
              <Form.Item
                name="productVariantIds"
                label={fm('label.product-variants')}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please select products'
                  }
                ]}
              >
                <ProductVariantSelect />
              </Form.Item>
              <Form.Item
                name="activeDate"
                label={fm('label.active-date')}
                {...rangeConfig}
              >
                <RangePicker showTime />
              </Form.Item>
              <Form.Item label={fm('label.cart-banner-block')} name="cartBannerBlockId">
                <Select
                  placeholder="Please select block"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {blocks.map((el) => (
                    <Select.Option key={el.id} value={el.id}>
                      {el.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Card>
          </Col>
          <Col xs={8}>
            <Card size="small" loading={isLoading}>
              <TranslationTabs form={form} emptyTranslation={emptyTranslation}>
                {({ namespace }) =>
                  <Form.Item
                    label={fm('label.translations.title')}
                    name={[namespace, 'title']}
                    // required={translation.locale === 'en'}
                  >
                    <Input />
                  </Form.Item>}
              </TranslationTabs>
            </Card>
            <Card
              size="small"
              loading={isLoading}
              title="Limits"
            >
              <Form.Item
                name="minQty"
                label={fm('label.min-quantity')}
              >
                <InputNumber
                  min={0}
                  step={1}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item
                name="minAmount"
                label={fm('label.min-amount')}
              >
                <InputNumber
                  min={0}
                  step={1}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item
                name="buyOneGetOneDiscounted"
                valuePropName="checked"
              >
                <Checkbox>Buy One Get One With Discount</Checkbox>
              </Form.Item>
            </Card>
          </Col>
          <FormActionBox
            isLoading={isLoading}
            newRecord={newRecord}
            onDelete={deleteRecord}
          />
        </Row>
      </Form>
    </PageHeader>
  )
}
