import { useEffect } from 'react'
import { Card, Table, PageHeader } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment'
import { fm } from '../../lang'
import { usePush } from '../../data/actions/routes'
import { useBreadcrumb } from '../../helpers/hooks/useBreadcrumb'
import { changeOrdersFilters } from '../../data/actions/orders'
import { useLocation } from 'react-router-dom'
import { OrdersTableHeader } from './OrdersTableHeader'
import { PaymentStatus } from './PaymentStatus'

export const DELIVERY_STATUSES = {
  jas_notified: 'JAS notified',
  jas_received: 'JAS received',
  jas_packed: 'JAS packed',
  jas_shipped: 'JAS shipped',
  shipped: 'Manually shipped'
}

export const OrdersTable = ({ path, isLoading }) => {
  const breadcrumb = useBreadcrumb(path)
  const push = usePush()
  const dispatch = useDispatch()
  const { entriesById, entryIds, totalCount, totalPayed } = useSelector((state) => state.orders)
  const entries = entryIds.map((id) => entriesById[id])
  const filters = useSelector((state) => state.orders.filters)
  const { pagination, sort } = filters
  const { pageSize, current } = pagination
  const location = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const page = parseInt(params.get('page') ?? 1)
    if (current !== page) {
      changeOrdersFilters(dispatch, { pagination: { ...pagination, current: page } }, push)
    }
  }, [])

  const columns = [
    {
      title: fm('table.number'),
      key: 'number',
      dataIndex: 'number',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: sort.by === 'number' && sort.order,
      sorter: true
    },
    {
      title: 'Date',
      key: 'createdAt',
      dataIndex: 'createdAt',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: sort.by === 'createdAt' && sort.order,
      sorter: true,
      render: (createdAt) => {
        return moment(createdAt).format('MMMM Do YYYY HH:mm')
      }
    },
    {
      title: 'Payment method',
      key: 'paymentMethod',
      dataIndex: 'paymentMethod'
    },
    {
      title: 'Total',
      key: 'total',
      dataIndex: 'total'
    },
    {
      title: 'Payment status',
      key: 'paymentStatus',
      dataIndex: 'paymentStatus',
      render: (paymentStatus, record) => {
        return <PaymentStatus status={paymentStatus} order={record} />
      }
    },
    {
      title: 'Delivery status',
      key: 'lastDeliveryStatus',
      dataIndex: 'lastDeliveryStatus',
      render: (lastDeliveryStatus) => {
        return DELIVERY_STATUSES[lastDeliveryStatus?.identifier] || '—'
      }
    },
    {
      title: 'Customer',
      key: 'customer',
      dataIndex: 'customer',
      render: (customer) => {
        if (customer) {
          return <span>{customer.fullName} - {customer.email}</span>
        } else {
          return '—'
        }
      }
    }
  ]
  const handleChange = (pagination, filters, sorters) => {
    changeOrdersFilters(dispatch, {
      pagination,
      sort: { by: sorters.columnKey, order: sorters.order }
    }, push)
  }

  return (
    <PageHeader breadcrumb={breadcrumb} title={fm('orders.title')}>
      <Card size="small">
        <OrdersTableHeader />
      </Card>
      <div style={{ marginBottom: 16 }} />
      <Card size="small">
        <Table
          onRow={(record) => ({
            onClick: () => push(`/orders/${record.id}`)
          })}
          onChange={handleChange}
          dataSource={entries}
          columns={columns}
          size="small"
          loading={isLoading}
          rowKey="id"
          pagination={{
            total: totalCount,
            pageSize,
            current
          }}
          summary={() => (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={4}>
                  Total: {totalCount}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} colSpan={2}>
                  {totalPayed.map(({ shopId, total }) => (
                    <div key={shopId}>{total}</div>
                  ))}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </Card>
    </PageHeader>
  )
}
