import { Tag, Tooltip } from 'antd'
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined
} from '@ant-design/icons'

export const PaymentStatus = ({ status, order }) => {
  const warning = order.paymentPriceCents !== order.totalCents

  switch (status) {
    case 'succeeded':
    case 'COMPLETED':
    case 'CAPTURED':
      return (
        <Tag
          color={warning ? 'warning' : 'green'}
          icon={warning ? <ExclamationCircleOutlined /> : <CheckCircleOutlined />}
        >
          SUCCESS - {order.paymentPrice}
        </Tag>
      )
    case 'requires_payment_method':
    case 'PENDING':
      return (
        <Tag
          color={warning ? 'warning' : 'cyan'}
          icon={warning ? <ExclamationCircleOutlined /> : <ClockCircleOutlined />}
        >
          PENDING - {order.paymentPrice}
        </Tag>
      )
    default:
      return (
        <Tag
          color={warning ? 'warning' : 'red'}
          icon={
            <Tooltip title={status} color="red">
              <CloseCircleOutlined />
            </Tooltip>
          }
        >
          ERROR - {order.paymentPrice}
        </Tag>
      )
  }
}
