import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Input, Typography, DatePicker, Select, Radio, Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { useDebouncedCallback } from 'use-debounce'
import _ from 'lodash-contrib'
import moment from 'moment'

import { usePush } from '../../data/actions/routes'
import { changeOrdersFilters } from '../../data/actions/orders'
import { ProductVariantSelect } from '../Shared/ProductVariantSelect'
import { RAILS_SERVER } from '../../constants/api'

const { RangePicker } = DatePicker
const { Text, Title } = Typography

export const OrdersTableHeader = () => {
  const dispatch = useDispatch()
  const push = usePush()
  const { allVoucherTags } = useSelector((state) => state.orders)
  const vouchers = useSelector((state) => state.vouchers.entries)
  const discounts = useSelector((state) => state.discounts.entries)
  const shops = useSelector((state) => state.shops.entries)
  const countries = useSelector((state) => state.countries.entries)
  const countriesIndex = _.keyBy(countries, 'id')
  const states = useSelector((state) => state.states.entries)
  const statesIndex = _.groupBy(states, 'countryId')
  const filters = useSelector((state) => state.orders.filters)
  const {
    term, period, productVariantIds, voucherId, voucherTags, discountId,
    shopId, countryIds, stateIds, stateNames, paymentMethods, paid
  } = filters

  const searchQuery = {
    filters: _.omitBy({
      term,
      period,
      product_variant_ids: productVariantIds,
      voucher_id: voucherId,
      voucher_tags: voucherTags,
      discount_id: discountId,
      shop_id: shopId,
      country_ids: countryIds,
      state_ids: stateIds,
      state_names: stateNames,
      payment_methods: paymentMethods,
      paid
    }, (v) => !_.isBoolean(v) && _.isEmpty(v))
  }
  const exportOrdersUrl = `${RAILS_SERVER}/api/v1/export/orders.csv?${_.toQuery(searchQuery)}`
  const exportOrderItemsUrl = `${RAILS_SERVER}/api/v1/export/order_items.csv?${_.toQuery(searchQuery)}`

  const handleTermChange = useDebouncedCallback((e) => {
    changeOrdersFilters(dispatch, { term: e.target.value }, push)
  }, 500)
  const handlePeriodChange = (values) => {
    changeOrdersFilters(dispatch, { period: values?.map((value) => value.format('YYYY-MM-DD')) ?? [] }, push)
  }
  const selectPaymentMethod = (value) => {
    changeOrdersFilters(dispatch, { paymentMethods: value }, push)
  }
  const selectProductVariants = (productVariantIds) => {
    const value = productVariantIds.flatMap((id) => {
      const ids = id.split(',')
      return ids.length > 1 ? ids.splice(1) : ids
    })

    changeOrdersFilters(dispatch, { productVariantIds: value }, push)
  }
  const selectVoucher = (value) => {
    changeOrdersFilters(dispatch, { voucherId: value }, push)
  }
  const selectVoucherTags = (value) => {
    changeOrdersFilters(dispatch, { voucherTags: value }, push)
  }
  const selectDiscount = (value) => {
    changeOrdersFilters(dispatch, { discountId: value }, push)
  }
  const selectShop = (value) => {
    changeOrdersFilters(dispatch, { shopId: value }, push)
  }
  const selectCountries = (values) => {
    changeOrdersFilters(dispatch, { countryIds: values }, push)
  }
  const selectStates = (values) => {
    changeOrdersFilters(dispatch, { stateIds: values }, push)
  }
  const selectStateNames = (values) => {
    changeOrdersFilters(dispatch, { stateNames: values }, push)
  }
  const selectPaid = (e) => {
    changeOrdersFilters(dispatch, { paid: e.target.value }, push)
  }

  return (
    <>
      <Row gutter={[16, 4]}>
        <Col xs={24}>
          <Title level={5}>Filters</Title>
        </Col>
        <Col xs={6}>
          <Row>
            <Col xs={24}>
              <Text>Enter number or email</Text>
            </Col>
            <Col xs={24}>
              <Input
                allowClear
                onChange={handleTermChange}
                defaultValue={term}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={4}>
          <Row>
            <Col xs={24}>
              <Text>Period</Text>
            </Col>
            <Col xs={24}>
              <RangePicker onChange={handlePeriodChange} defaultValue={period.map((value) => moment(value))} />
            </Col>
          </Row>
        </Col>
        <Col xs={4}>
          <Row>
            <Col xs={24}>
              <Text>Payment method</Text>
            </Col>
            <Col xs={24}>
              <Select
                mode="multiple"
                allowClear
                onChange={selectPaymentMethod}
                style={{ width: '100%' }}
                defaultValue={paymentMethods}
              >
                <Select.Option key="stripe" value="stripe">
                  Stripe
                </Select.Option>
                <Select.Option key="apple-pay" value="apple-pay">
                  Apple Pay
                </Select.Option>
                <Select.Option key="google-pay" value="google-pay">
                  Google Pay
                </Select.Option>
                <Select.Option key="klarna" value="klarna">
                  Klarna
                </Select.Option>
                <Select.Option key="paypal" value="paypal">
                  Paypal
                </Select.Option>
                <Select.Option key="paypal-card" value="paypal-card">
                  Paypal Card
                </Select.Option>
                <Select.Option key="flip" value="flip">
                  Flip
                </Select.Option>
              </Select>
            </Col>
          </Row>
        </Col>
        <Col xs={10}>
          <Row>
            <Col xs={24}>
              <Text>Product variants</Text>
            </Col>
            <Col xs={24}>
              <ProductVariantSelect
                allowClear
                onChange={selectProductVariants}
                style={{ width: '100%' }}
                defaultValue={productVariantIds}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 4]}>
        <Col xs={6}>
          <Row>
            <Col xs={24}>
              <Text>Voucher</Text>
            </Col>
            <Col xs={24}>
              <Select
                allowClear
                onChange={selectVoucher}
                style={{ width: '100%' }}
                defaultValue={voucherId}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())}
              >
                {vouchers.map((el) => {
                  return (
                    <Select.Option key={el.id} value={el.id}>
                      {`${el.name} (${el.code})`}
                    </Select.Option>
                  )
                })}
              </Select>
            </Col>
          </Row>
        </Col>
        <Col xs={4}>
          <Row>
            <Col xs={24}>
              <Text>Voucher Tags</Text>
            </Col>
            <Col xs={24}>
              <Select
                mode="multiple"
                allowClear
                onChange={selectVoucherTags}
                style={{ width: '100%' }}
                defaultValue={voucherTags}
                options={allVoucherTags.map((tag) => ({ value: tag, label: tag }))}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          <Row>
            <Col xs={24}>
              <Text>Discount</Text>
            </Col>
            <Col xs={24}>
              <Select
                allowClear
                onChange={selectDiscount}
                style={{ width: '100%' }}
                defaultValue={discountId}
              >
                {discounts.map((el) => {
                  return (
                    <Select.Option key={el.id} value={el.id}>
                      {el.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Col>
          </Row>
        </Col>
        <Col xs={4}>
          <Row>
            <Col xs={24}>
              <Text>Marketplace</Text>
            </Col>
            <Col xs={24}>
              <Select
                allowClear
                onChange={selectShop}
                style={{ width: '100%' }}
                defaultValue={shopId}
              >
                {shops.map((el) => {
                  return (
                    <Select.Option key={el.id} value={el.id}>
                      {el.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Col>
          </Row>
        </Col>
        <Col xs={4}>
          <Row>
            <Col xs={24}>
              <Text>Payment status</Text>
            </Col>
            <Col xs={24}>
              <Radio.Group defaultValue={paid} onChange={selectPaid}>
                <Radio.Button>All</Radio.Button>
                <Radio.Button value>Paid</Radio.Button>
                <Radio.Button value={false}>Unpaid</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 4]}>
        <Col xs={6}>
          <Row>
            <Col xs={24}>
              <Text>Countries</Text>
            </Col>
            <Col xs={24}>
              <Select
                mode="multiple"
                allowClear
                onChange={selectCountries}
                style={{ width: '100%' }}
                defaultValue={countryIds}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())}
              >
                {countries.map((el) => {
                  return (
                    <Select.Option key={el.id} value={el.id}>
                      {el.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          <Row>
            <Col xs={24}>
              <Text>States</Text>
            </Col>
            <Col xs={24}>
              <Select
                mode="multiple"
                allowClear
                onChange={selectStates}
                style={{ width: '100%' }}
                defaultValue={stateIds}
                filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                options={
                  _.sortBy(
                    _.filter(
                      countryIds.length ? countryIds : Object.keys(countriesIndex),
                      (countryId) => statesIndex[countryId]
                    ).map((countryId) => ({
                      label: countriesIndex[countryId].name,
                      options: statesIndex[countryId].map((state) => ({ label: state.name, value: state.id }))
                    })),
                    'label'
                  )
                }
              />
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          <Row>
            <Col xs={24}>
              <Text>State names</Text>
            </Col>
            <Col xs={24}>
              <Select
                mode="tags"
                allowClear
                onChange={selectStateNames}
                style={{ width: '100%' }}
                defaultValue={stateNames}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          <Row style={{ paddingTop: '22px' }}>
            <Col xs={12}>
              <Button type="primary" icon={<DownloadOutlined />} href={exportOrdersUrl}>
                Export orders CSV
              </Button>
            </Col>
            <Col xs={12}>
              <Button type="primary" icon={<DownloadOutlined />} href={exportOrderItemsUrl}>
                Export order items CSV
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
