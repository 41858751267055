import { useCallback, useState } from 'react'
import { Button, Card, Col, Row, Modal, Form, Input } from 'antd'
import { MailOutlined, SendOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import { fm } from '../../../lang'
import moment from 'moment'
import _ from 'lodash'

import { sendOrderEmail, submitOrderTracking } from '../../../data/actions/orders'
import { useAllow } from '../../../helpers/hooks/usePermissions'
import { AddressText } from '../../Shared/AdressText'
import { DELIVERY_STATUSES } from '../OrdersTable'
import { PaymentStatus } from '../PaymentStatus'

const formatAddress = (address, countries) => {
  const country = countries.find((el) => el.id === address?.countryId)

  return _.reject([
    address.fullName,
    address.address,
    address.address2,
    address.city,
    address.stateName,
    country?.name,
    address.postcode
  ], _.isEmpty).join(',\n')
}

export const BasicInfo = ({ loading, entry }) => {
  const {
    number,
    customer,
    shopId,
    deliveryAddress,
    paymentAddress,
    paymentMethod,
    createdAt,
    paymentStatus,
    lastDeliveryStatus,
    voucher,
    externalReference,
    emailStatsConfirmation,
    sendingEmail,
    submittingTracking
  } = entry
  const shops = useSelector((state) => state.shops.entries)
  const countries = useSelector((state) => state.countries.entries)
  const shop = shops.find((el) => el.id === shopId)
  const dispatch = useDispatch()
  const allow = useAllow()
  const [modalOpen, setModalOpen] = useState(false)

  const copyAddressToBuffer = useCallback(() => {
    navigator.clipboard.writeText(formatAddress(deliveryAddress, countries))
  }, [deliveryAddress, countries])
  const copyPaymentAddressToBuffer = useCallback(() => {
    navigator.clipboard.writeText(formatAddress(paymentAddress, countries))
  }, [paymentAddress, countries])
  const sendConfirmationEmail = useCallback(() => {
    sendOrderEmail(dispatch, entry.id, { emailType: 'confirmation' })
  }, [dispatch, entry.id])

  const [form] = Form.useForm()
  const openModal = useCallback(() => { setModalOpen(true) }, [])
  const closeModal = useCallback(() => { setModalOpen(false) }, [])
  const handleModalOk = useCallback(() => { form.submit() }, [form])
  const handleSubmittingTracking = useCallback(async (values) => {
    submitOrderTracking(dispatch, entry.id, values)
    setModalOpen(false)
  }, [dispatch, entry.id])

  return (
    <Row gutter={[16, 16]}>
      <Col xs={12}>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Card
              size="small"
              loading={loading}
              title={fm('title.order-info')}
            >
              <table>
                <tbody>
                  <tr>
                    <td valign="top">
                      <b>{fm('label.number')}</b>
                    </td>
                    <td style={{ paddingLeft: 16 }}>{number}</td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <b>Date</b>
                    </td>
                    <td style={{ paddingLeft: 16 }}>{createdAt}</td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <b>{fm('label.shop')}</b>
                    </td>
                    <td style={{ paddingLeft: 16 }}> {shop ? shop.name : '—'}</td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <b>{fm('label.customer')}</b>
                    </td>
                    <td style={{ paddingLeft: 16 }}>
                      {customer && customer.email}
                      <br />
                      {customer && customer.fullName}
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <b>Delivery status</b>
                    </td>
                    <td style={{ paddingLeft: 16 }}>
                      {lastDeliveryStatus
                        ? `${DELIVERY_STATUSES[lastDeliveryStatus.identifier]} at ${moment(lastDeliveryStatus.occurred_at).format('LLL')}`
                        : '—'}
                      {
                        allow('can_submit_tracking_order') &&
                          <>
                            <Button
                              type="primary"
                              size="small"
                              style={{ marginLeft: '8px' }}
                              icon={<SendOutlined />}
                              loading={submittingTracking}
                              onClick={openModal}
                            >
                              Submit tracking
                            </Button>
                            <Modal
                              title="Submit order tracking"
                              destroyOnClose
                              open={modalOpen}
                              confirmLoading={submittingTracking}
                              onOk={handleModalOk}
                              onCancel={closeModal}
                            >
                              <Form
                                form={form}
                                layout="vertical"
                                onFinish={handleSubmittingTracking}
                              >
                                <Form.Item
                                  name="carrier"
                                  label={fm('label.carrier')}
                                  rules={[
                                    {
                                      required: true
                                    }
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  name="trackingNumber"
                                  label={fm('label.tracking-number')}
                                  rules={[
                                    {
                                      required: true
                                    }
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Form>
                            </Modal>
                          </>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <b>External reference</b>
                    </td>
                    <td style={{ paddingLeft: 16 }}>
                      {externalReference ?? '—'}
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <b>Client email confirmation</b>
                    </td>
                    <td style={{ paddingLeft: 16 }}>
                      {emailStatsConfirmation ? 'Yes' : 'No'}
                      {
                        allow('can_send_email_order') &&
                          <Button
                            type="primary"
                            size="small"
                            style={{ marginLeft: '8px' }}
                            icon={<MailOutlined />}
                            loading={sendingEmail}
                            onClick={sendConfirmationEmail}
                          >
                            Send again
                          </Button>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </Col>
          <Col xs={24}>
            <Card size="small" loading={loading} title="Payment details">
              <table>
                <tbody>
                  <tr>
                    <td valign="top">
                      <b>Method</b>
                    </td>
                    <td style={{ paddingLeft: 16 }}>{paymentMethod}</td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <b>Status</b>
                    </td>
                    <td style={{ paddingLeft: 16 }}>
                      <PaymentStatus status={paymentStatus} order={entry} />
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <b>Voucher</b>
                    </td>
                    <td style={{ paddingLeft: 16 }}>
                      {voucher && voucher.cents > 0
                        ? `${voucher.text} • ${voucher.value}`
                        : '—'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col xs={6}>
        <Card
          size="small"
          title={fm('order.delivery-address')}
          loading={loading}
          extra={
            <Col xs={24}>
              <Button type="primary" onClick={copyAddressToBuffer}>
                Copy
              </Button>
            </Col>
          }
        >
          <Row>
            <Col xs={24}>
              <AddressText address={deliveryAddress} />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={6}>
        <Card
          size="small"
          title={fm('order.payment-address')}
          loading={loading}
          extra={
            <Col xs={24}>
              <Button type="primary" onClick={copyPaymentAddressToBuffer}>
                Copy
              </Button>
            </Col>
          }
        >
          <AddressText address={paymentAddress} />
        </Card>
      </Col>
    </Row>
  )
}
