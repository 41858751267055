import { useCallback } from 'react'
import { Row, Col, PageHeader } from 'antd'
import { useSelector } from 'react-redux'

import { fm } from '../../lang'
import { useRecordHook } from '../../helpers/hooks/useRecordHook'
import { usePush } from '../../data/actions/routes'
import { useBreadcrumb } from '../../helpers/hooks/useBreadcrumb'
import { OrderItemsTable } from './Form/OrderItemsTable'
import { useSubscription } from '@logux/redux'
import { BasicInfo } from './Form/BasicInfo'

export const OrderForm = ({ path }) => {
  const breadcrumb = useBreadcrumb(path)
  const push = usePush()
  const { params: { id } } = useRecordHook()
  const isLoading = useSubscription([`orders/${id}`])
  const { entriesById: { [id]: entry } } = useSelector((state) => state.orders)
  const { pagination: { current } } = useSelector((state) => state.orders.filters)
  const handleBack = useCallback(() => {
    push(`/orders?page=${current}`)
  }, [push, current])

  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm('order.form.edit')}
      onBack={handleBack}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <BasicInfo
            loading={!entry && isLoading}
            entry={entry ?? {}}
          />
        </Col>
        <Col xs={24}>
          <OrderItemsTable
            loading={!entry && isLoading}
            items={entry?.items}
            details={entry?.details}
            giftMessage={entry?.giftMessage}
          />
        </Col>
      </Row>
    </PageHeader>
  )
}
