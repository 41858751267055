import * as types from '../../constants/actionTypes/ordersActionTypes'

export const changeOrdersFilters = (dispatch, payload, push) => {
  dispatch.local({
    type: types.ORDERS_CHANGE_FILTERS,
    payload
  })
  push(`/orders?page=${payload?.pagination?.current || 1}`)
}

export const sendOrderEmail = (dispatch, id, payload) => {
  dispatch.sync({
    type: types.ORDER_SEND_EMAIL,
    id,
    order: {
      ...payload,
      id
    }
  })
}

export const submitOrderTracking = (dispatch, id, payload) => {
  dispatch.sync({
    type: types.ORDER_SUBMIT_TRACKING,
    id,
    order: {
      ...payload,
      id
    }
  })
}
