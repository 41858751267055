import { createSelector } from 'reselect'
import _ from 'lodash'

const getProducts = (state) => state.products.entries
const getFilters = (state) => state.products.tableFilters

export const getListProducts = createSelector(getProducts, (products) => {
  return _.map(products, (p) => ({ id: p.id, title: p.name }))
})

export const getFilteredProducts = createSelector(
  getProducts,
  getFilters,
  (products, filters) => {
    if (products.length === 0) return []
    let result = products
    if (filters.categories && filters.categories.length > 0) {
      result = result.filter((el) => {
        return filters.categories.includes(el.categoryId)
      })
    }
    if (filters.term && filters.term !== '') {
      result = result.filter(({ translations }) => {
        return translations.find(({ locale }) => locale === 'en').name
          .toLowerCase().includes(filters.term.toLowerCase())
      })
    }
    if (filters.lowStock) {
      result = result.filter(({ variants }) => {
        return variants.find(({ warehouseQuantity, availableQuantity, futureQuantity, futureDeliveryDate }) =>
          futureDeliveryDate && availableQuantity <= 0 ? futureQuantity < 10 : warehouseQuantity > 0 && availableQuantity < 10)
      })
    }
    return result
  }
)
