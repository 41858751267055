/* eslint "sort-keys": "error" */
export default {
  'account.basic-settings.title': 'Basic settings',
  'action-box.message-delete': 'Are you sure to delete?',
  'address.form.deliveryAddresses': 'Shipping addresses',
  'address.form.paymentAddresses': 'Payment addresses',
  'alert.system-block': 'This is a system block.',
  'alert.system-block-not-preview': 'The system block cannot be previewed',
  'alert.system-role': 'This is a system role and cannot be changed.',
  'article.form.edit': 'Edit article',
  'article.form.new': 'New article',
  'articles.published-status.all': 'All',
  'articles.published-status.draft': 'Draft',
  'articles.published-status.published': 'Published',
  'articles.table.title': 'Title',
  'articles.title': 'Articles',
  'block.form.edit': 'Edit block',
  'block.form.kind.articles': 'Articles',
  'block.form.kind.categories': 'Categories',
  'block.form.kind.collection': 'Collection',
  'block.form.kind.compare': 'Compare',
  'block.form.kind.editor': 'Editor',
  'block.form.kind.header': 'Header',
  'block.form.kind.html': 'HTML',
  'block.form.kind.null': '—',
  'block.form.kind.system': 'System',
  'block.form.kind.undefined': '—',
  'block.form.kind.webflow': 'Webflow',
  'block.form.new': 'New block',
  'blocks.table.menu.articles': 'Articles',
  'blocks.table.menu.categories': 'Categories',
  'blocks.table.menu.collection': 'Collection',
  'blocks.table.menu.compare': 'Compare',
  'blocks.table.menu.editor': 'Editor',
  'blocks.table.menu.html': 'HTML',
  'blocks.table.menu.webflow': 'Webflow',
  'blocks.title': 'Blocks',
  'breadcrumbs.account': 'Account',
  'breadcrumbs.articles': 'Articles',
  'breadcrumbs.assortment': 'Assortment ',
  'breadcrumbs.blocks': 'Blocks',
  'breadcrumbs.categories': 'Categories',
  'breadcrumbs.colors': 'Colors',
  'breadcrumbs.content': 'Content',
  'breadcrumbs.customers': 'Customers',
  'breadcrumbs.customers.new': 'New customer',
  'breadcrumbs.discounts': 'Discounts',
  'breadcrumbs.edit': 'Edit',
  'breadcrumbs.free-forms': 'Forms',
  'breadcrumbs.new': 'New',
  'breadcrumbs.orders': 'Orders',
  'breadcrumbs.pages': 'Pages',
  'breadcrumbs.products': 'Products',
  'breadcrumbs.redirects': 'Redirects',
  'breadcrumbs.roles': 'Roles',
  'breadcrumbs.settings': 'Settings',
  'breadcrumbs.shops': 'Marketplaces',
  'breadcrumbs.specification-groups': 'Specification groups',
  'breadcrumbs.specifications': 'Specifications',
  'breadcrumbs.tags': 'Tags',
  'breadcrumbs.users': 'Users',
  'breadcrumbs.users.new': 'New',
  'breadcrumbs.vouchers': 'Vouchers',
  'breadcrumbs.websites': 'Websites',
  'btn.add-vimeo': 'Add vimeo',
  'btn.upload-file': 'Upload file',
  'button.add': 'Add',
  'button.add-address': 'Add address',
  'button.add-audio': 'Add audio',
  'button.add-new-property': 'Add property',
  'button.add-products': 'Add products',
  'button.add-to-order': 'Add to order',
  'button.add-url': 'Add url',
  'button.add-variant': 'Add variant',
  'button.cancel': 'Cancel',
  'button.change-status-to-pending': 'Change the status to "Pending"',
  'button.clear': 'Clear',
  'button.copy-shipping-address': 'Copy shipping address',
  'button.create': 'Create',
  'button.delete': 'Delete',
  'button.edit': 'Edit',
  'button.fetch-translate': 'Update translate',
  'button.hide-form': 'Hide',
  'button.log-in': 'Log in',
  'button.new': 'New',
  'button.no': 'No',
  'button.remove': 'Remove',
  'button.show-form': 'Edit',
  'button.take-order': 'Take order',
  'button.update': 'Update',
  'button.update-information': 'Update information',
  'button.yes': 'Yes',
  'categories.title': 'Categories',
  'category.form.edit': 'Edit category',
  'category.form.new': 'New category',
  'checkbox.agree-to-gdpr': 'Agree to GDPR',
  'checkbox.agree-to-subscribe': 'Agree to subscribe',
  'customer.form.edit': 'Edit customer',
  'customer.form.info': 'Info',
  'customer.form.new': 'New customer',
  'customer.form.primary-address': 'Primary address',
  'customers.title': 'Customers',
  'discount.form.edit': 'Edit discount',
  'discount.form.new': 'New discount',
  'discounts.table.name': 'Name',
  'discounts.table.type': 'Type',
  'discounts.title': 'Discounts',
  'error.denied': 'Access Denied',
  'error.record_not_found': 'Record not found',
  'error.wrong': 'Wrong email or password',
  'free-form.basic': 'Form',
  'free-form.fields': 'Fields',
  'free-form.form.edit': 'Edit form',
  'free-form.form.new': 'New form',
  'free-forms.table.active': 'Active',
  'free-forms.table.end-at': 'End at',
  'free-forms.table.fields': 'Fields',
  'free-forms.table.identifier': 'Identifier',
  'free-forms.table.start-at': 'Start at',
  'free-forms.title': 'Forms',
  'input.email': 'E-mail',
  'input.password': 'Password',
  'label.active-date': 'Active date',
  'label.active-time': 'Active time',
  'label.address': 'Address',
  'label.address2': 'Apartment, suite, etc.',
  'label.alias': 'Alias',
  'label.applicable-with-discount': 'Applicable with discount',
  'label.articles': 'Articles',
  'label.audio-file': 'File',
  'label.audio-file-attached': 'File attached',
  'label.block': 'Block',
  'label.blocks': 'Blocks',
  'label.carrier': 'Carrier',
  'label.cart-banner-block': 'Cart banner block',
  'label.category': 'Category',
  'label.city': 'City',
  'label.code': 'Code',
  'label.color': 'Color',
  'label.company': 'Company',
  'label.confirm-password': 'Confirm password',
  'label.content': 'Content',
  'label.countries': 'Countries',
  'label.country': 'Country',
  'label.currency': 'Currency',
  'label.customer': 'Customer',
  'label.date-of-order': 'Created',
  'label.default-country': 'Default country',
  'label.discount-types': 'Types',
  'label.ean': 'EAN',
  'label.email': 'Email',
  'label.enter-name-or-email': 'Enter name or email:',
  'label.first-name': 'First name',
  'label.full-name': 'Full name',
  'label.future-delivery-date': 'Future delivery date',
  'label.future-quantity': 'Future quantity',
  'label.gift-voucher-percentage': 'Order generated gift voucher percentage value',
  'label.gift-voucher-product-ids': 'Order generated gift voucher applicable products',
  'label.google-product-category': 'Google product category',
  'label.group': 'Group',
  'label.identifier': 'Identifier',
  'label.klarna-min-total': 'Klarna minimum required order total',
  'label.lang-title': 'Title({lang})',
  'label.lang-value': 'Value({lang})',
  'label.last-name': 'Last name',
  'label.locale': 'Language',
  'label.location': 'To location',
  'label.main': 'Main',
  'label.marketplaces': 'Marketplaces',
  'label.max-usages': 'Max usages',
  'label.media': 'Media',
  'label.min-amount': 'Minimum purchase amount',
  'label.min-quantity': 'Minimum quantity of items',
  'label.name': 'Name',
  'label.notification-emails': 'Notification emails',
  'label.number': 'Number',
  'label.order-notification-emails': 'Order notification emails',
  'label.parent-node-class': 'Parent node class',
  'label.password': 'Password',
  'label.pattern': 'From',
  'label.phone': 'Phone Number',
  'label.postal-code': 'Postal code',
  'label.price-eur': 'Price € (EUR)',
  'label.price-gbp': 'Price £ (GBP)',
  'label.price-usd': 'Price $ (USD)',
  'label.product-variants': 'Product variants',
  'label.products': 'Products',
  'label.published': 'Published',
  'label.published-at': 'Published date',
  'label.quantity': 'Quantity',
  'label.rating': 'Rating',
  'label.related-products': 'Related products',
  'label.required': 'Required',
  'label.review-count': 'Review count',
  'label.role': 'Role',
  'label.sad-vat': 'SAD VAT',
  'label.sage-warehouse': 'Sage warehouse',
  'label.shop': 'Marketplace',
  'label.shops': 'Shops',
  'label.short-name': 'Short name',
  'label.sku': 'SKU',
  'label.slug': 'Slug',
  'label.specification': 'Specification',
  'label.stateName': 'State',
  'label.stripe-country': 'Stripe account country',
  'label.tags': 'Tags',
  'label.template': 'Template',
  'label.theme': 'Theme',
  'label.title': 'Title',
  'label.title-key': 'Title translate key',
  'label.tracking-number': 'Tracking number',
  'label.translations.details': 'Details',
  'label.translations.feature': 'Feature',
  'label.translations.long-text': 'Long text',
  'label.translations.name': 'Name',
  'label.translations.seo-description': 'SEO Description',
  'label.translations.seo-keywords': 'SEO Keywords',
  'label.translations.seo-title': 'SEO Title',
  'label.translations.short-text': 'Short text',
  'label.translations.slug': 'Slug',
  'label.translations.social-description': 'Facebook/Twitter Description',
  'label.translations.social-title': 'Facebook/Twitter Title',
  'label.translations.theme': 'Theme',
  'label.translations.title': 'Title',
  'label.type-name': 'Type',
  'label.value': 'Value',
  'label.video-code': 'Video code (Vimeo)',
  'label.video-title': 'Title (HTML, Text)',
  'label.visual-style': 'Visual type',
  'label.voucher-type': 'Voucher type',
  'label.websites': 'Websites',
  'label.weight': 'Weight',
  'menu.articles': 'Articles',
  'menu.blocks': 'Blocks',
  'menu.categories': 'Categories',
  'menu.customers': 'Customers',
  'menu.dashboard': 'Dashboard',
  'menu.discounts': 'Discounts',
  'menu.free-forms': 'Forms',
  'menu.orders': 'Orders',
  'menu.pages': 'Pages',
  'menu.products': 'Products',
  'menu.redirects': 'Redirects',
  'menu.roles': 'Roles',
  'menu.shops': 'Marketplaces',
  'menu.spec-group': 'Spec. group',
  'menu.specification': 'Specification',
  'menu.tags': 'Tags',
  'menu.users': 'Users',
  'menu.vouchers': 'Vouchers',
  'menu.websites': 'Websites',
  'message.block-sync': 'Wait. Sync with server.',
  'modal.title.new-address': 'New address',
  'order.delivery-address': 'Shipping address',
  'order.form.edit': 'Edit order',
  'order.form.new': 'New order',
  'order.payment-address': 'Payment address',
  'order.status.completed': 'Completed',
  'order.status.delivered': 'Delivered',
  'order.status.new': 'New',
  'order.status.packing': 'Packing',
  'order.status.pending': 'Pending',
  'order.status.processing': 'Processing',
  'order.status.ready-to-pack': 'Ready to pack',
  'order.status.shipped': 'Shipped',
  'orders.title': 'Orders',
  'page.form.edit': 'Edit page',
  'page.form.new': 'New page',
  'page.title.page-type': 'Page template',
  'page.title.seo': 'SEO',
  'page.title.url': 'Url',
  'pages.published-status.all': 'All',
  'pages.published-status.draft': 'Draft',
  'pages.published-status.published': 'Published',
  'pages.table.block-not-found': 'Block not found',
  'pages.table.menu.collection': 'Collection page',
  'pages.table.menu.custom': 'Custom page',
  'pages.table.menu.product': 'Product page',
  'pages.table.menu.support': 'Support page',
  'pages.table.menu.system': 'System page',
  'pages.title': 'Pages',
  'product.basic': 'Basic',
  'product.form.edit': 'Edit product',
  'product.form.new': 'New product',
  'product.form.tabs.audio': 'Audio',
  'product.form.tabs.basic': 'Basic',
  'product.form.tabs.colors': 'Colors',
  'product.form.tabs.content': 'Content',
  'product.form.tabs.faq': 'FAQ',
  'product.form.tabs.media': 'Media',
  'product.form.tabs.specification': 'Specification',
  'product.form.tabs.support': 'Support',
  'product.form.tabs.text': 'Text',
  'product.form.tabs.translations': 'Translations',
  'product.form.tabs.tutorial': 'Tutorial',
  'product.form.tabs.variants': 'Variants',
  'product.media': 'Media',
  'product.prices': 'Prices',
  'product.tags': 'Tags',
  'product.text': 'Text',
  'product.variants': 'Variants',
  'products.title': 'Products',
  'redirect.form.edit': 'Edit redirect',
  'redirect.form.new': 'New redirect',
  'redirects.title': 'Redirects',
  'role.form.edit': 'Role edit',
  'role.form.new': 'New role',
  'roles.title': 'Roles',
  'shop.form.edit': 'Edit marketplace',
  'shop.form.locale.tooltip': 'The first locale is loaded by default.',
  'shop.form.new': 'New marketplace',
  'shops.title': 'Marketplaces',
  'specification-group.form.edit': 'Edit spec. group',
  'specification-group.form.new': 'New spec. group',
  'specification-groups.title': 'Spec. groups',
  'specification.form.edit': 'Edit specification',
  'specification.form.new': 'New specification',
  'specifications.title': 'Specifications',
  'spin.connecting': 'Connecting...',
  'sub-menu.assortment': 'Assortment',
  'sub-menu.content': 'Content',
  'sub-menu.settings': 'Settings',
  'tab.basic': 'Basic',
  'tab.blocks': 'Blocks',
  'tab.content': 'Content',
  'tab.marketplace': 'Marketplace',
  'tab.pages': 'Pages',
  'table.actions': 'Actions',
  'table.address.column.name': 'Address #{number}',
  'table.alias': 'Alias',
  'table.blocks': 'Blocks',
  'table.created-at': 'Created on',
  'table.description': 'Description',
  'table.email': 'Email',
  'table.end-at': 'End on',
  'table.full-name': 'Full name',
  'table.image': 'Image',
  'table.kind': 'Kind',
  'table.locale': 'Language',
  'table.location': 'To location',
  'table.name': 'Name',
  'table.no-address': 'No address',
  'table.number': 'Number',
  'table.pages-count': 'Pages count',
  'table.pattern': 'From',
  'table.price': 'Price',
  'table.published-at': 'Published on',
  'table.quantity': 'Quantity',
  'table.shop': 'Marketplace',
  'table.shop-not-selected': 'Marketplace not selected',
  'table.slug': 'Slug',
  'table.start-at': 'Start on',
  'table.tags': 'Tags',
  'table.title': 'Title',
  'table.websites': 'Websites',
  'tag.form.edit': 'Edit tag',
  'tag.form.new': 'New tag',
  'tags.title': 'Tags',
  'text.no-address': 'No address',
  'text.please-select-or-write-address': 'Please select or write address',
  'text.upload-files': 'Upload files',
  'title.basic-properties': 'Basic properties',
  'title.delivery-countries': 'Delivery countries',
  'title.order-info': 'Details',
  'title.permissions': 'Permissions',
  'tooltip.gdpr-text': 'The customer must accept it on the site himself.',
  'tooltip.select-address': 'You can also edit the address.',
  'tooltip.video-code-tooltip': 'Insert only <iframe> code. Without scripts.',
  'user-menu.account': 'Account',
  'user-menu.logout': 'Logout',
  'user.form.edit': 'User edit',
  'user.form.new': 'New user',
  'users.table.email': 'Email',
  'users.title': 'Users',
  'validate.error.email': 'Please input your email',
  'validate.error.password': 'Please input your password',
  'voucher.form.edit': 'Edit voucher',
  'voucher.form.new': 'New voucher',
  'vouchers.table.code': 'Code',
  'vouchers.table.discount-type': 'Type',
  'vouchers.table.name': 'Name',
  'vouchers.table.usages': 'Used',
  'vouchers.title': 'Vouchers',
  'website.form.edit': 'Website edit',
  'website.form.new': 'New website',
  'website.prefix': 'Url prefix',
  'websites.title': 'Websites'
}
