import * as types from '../../constants/actionTypes/freeFormsActionTypes'
import { v4 as uuidv4 } from 'uuid'

const emptyFreeForm = {
  id: null,
  identifier: '',
  startAt: '',
  endAt: '',
  notificationEmails: [],
  fields: [],
  html: ''
}

export const emptyFreeFormField = {
  id: null,
  identifier: '',
  typeName: '',
  required: false
}

const defaultState = {
  entry: { ...emptyFreeForm, id: uuidv4() },
  entries: []
}

export default function freeFormsReducer (state = defaultState, action) {
  switch (action.type) {
    case types.FREE_FORMS_UPDATE:
      return {
        ...state,
        entries: state.entries.map((u) => {
          if (u.id === action.freeForm.id) {
            return { ...action.freeForm }
          } else {
            return u
          }
        })
      }
    case types.FREE_FORMS_DELETE:
      return {
        ...state,
        entry: { ...emptyFreeForm, id: uuidv4() },
        entries: state.entries.filter((u) => u.id !== action.freeForm.id)
      }
    case types.FREE_FORM_FETCH:
      return { ...state, entry: action.data }
    case types.FREE_FORMS_CREATE:
      return {
        ...state,
        entries: [{ ...action.freeForm }, ...state.entries]
      }
    case types.FREE_FORM_SELECT:
      return { ...state, entry: state.entries.find((u) => u.id === action.id) }
    case types.FREE_FORM_FORM_RESET:
      return { ...state, entry: { ...emptyFreeForm, id: uuidv4() } }
    case types.FREE_FORMS_FETCH:
      return { ...state, entries: action.data.records, typeNames: action.data.typeNames }
    default:
      return state
  }
}
